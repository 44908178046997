import '~/client/styles/reset.css';
import '~/client/styles/globals.css';
import type { AppProps } from 'next/app';
import { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { appWithTranslation } from 'next-i18next';
import client from '~/client/apollo/client';
import { ErrorBoundary } from '@quotalogic/canary';

interface Props extends AppProps {
    pageProps: {
        apolloStaticCache?: NormalizedCacheObject
    }
}

function App({ Component, pageProps }: Props) {
  const apolloClient = client(pageProps?.apolloStaticCache);

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <Component {...pageProps} />
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default appWithTranslation(App);
