import { OctagonXmark } from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import styles from './Fallback.module.css';

export const Fallback = () => {
  const { t } = useTranslation('error');

  return (
    <div className={styles.layout}>
      <div className={styles.message}>
        <OctagonXmark className={styles.icon} />
        <h1 className={styles.title}>
          {t('title')}
        </h1>
        <p className={styles.text}>
          {t('text')}
        </p>
      </div>
    </div>
  );
};
